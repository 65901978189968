<template>
  <!-- Activities Hub -->
  <v-dialog v-model="dialog" width="60%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" depressed x-small dark color="purple">
        <v-icon size="18">mdi-transfer</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">
          <v-icon style="margin-right: 10px;">mdi-transfer</v-icon>Transfer
          Question
        </span>
        <v-divider></v-divider>
        <v-icon color="red" @click="dialog = false">mdi-close-thick</v-icon>
      </v-card-title>
      <v-card-text>
        <v-row>
          <!--End Filter by Month-->
          <v-col cols="10">
            <v-autocomplete
              :items="weeks"
              v-model="selected"
              label="Select Lesson"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn depressed color="success" small @click="copyQuestion(selected)"
          >Copy</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- End Activities Hub -->
</template>
<script>
import firebase from "@/Firebase";
export default {
  name:"TransferQuestion",
  props: ["path"],
  data() {
    return {
      dialog: false,
      activities: [],
      csystems: [],
      grades: [],
      subjects: [],
      newActivity: {},
      videolink: "",
      selectedTitle: "",
      selectedWeek: "",
      selected: "",
      system: "",
      grade: "",
      weekid: "",
      weeks: [],
      subject: this.$store.state.subject,
      oldquestion: {},
      sfields: [
        {
          field: "type",
          label: "",
          sortable: true,
          class: "text-left"
        },
        {
          field: "system",
          label: "System",
          sortable: true,
          class: "text-left"
        },
        {
          field: "grade",
          label: "Grade",
          sortable: true,
          class: "text-left"
        },
        {
          field: "subject",
          label: "Subject",
          sortable: true,
          class: "text-left"
        },
        {
          field: "week",
          label: "Week",
          sortable: true,
          class: "text-left"
        },
        {
          field: "title",
          label: "Title",
          sortable: true,
          class: "text-left"
        },
        {
          field: "actions",
          label: "",
          sortable: true,
          class: "text-left",
          width: "250px"
        }
      ]
    };
  },

  created() {
    this.loadweeks();
  },
  methods: {
    loadweeks() {
      firebase
        .firestore()
        .collection(this.subject + "/weeks")
        .orderBy("order")
        .onSnapshot(querySnapshot => {
          this.weeks = [];
          querySnapshot.forEach(doc => {
            this.weeks.push({
              key: doc.id,
              text: doc.data().title,
              value: doc.ref.path
            });
          });
        });
    },

    copyQuestion(targetweek) {
      firebase
        .firestore()
        .doc(this.path)
        .get()
        .then(doc => {
          this.oldquestion = doc;
console.log(this.oldquestion.data())
          firebase
            .firestore()
            .collection(targetweek + "/questions")
            .doc(doc.id)
            .set(this.oldquestion.data());
        });

      //! Options
      firebase
        .firestore()
        .collection(this.path + "/options")
        .get()
        .then(snapShot => {
          snapShot.docs.forEach(element => {
            firebase
              .firestore()
              .collection(
                targetweek + "/questions/" + this.oldquestion.id + "/options"
              )
              .doc(element.id)
              .set(element.data());
          });
        });

      firebase
        .firestore()
        .doc(this.path + "/details/details")
        .get()
        .then(doc => {
          const details = doc.data();

          //! Details
          firebase
            .firestore()
            .collection(targetweek + "/questions")
            .doc(this.oldquestion.id + "/details/details")
            .set(details)
            .then(() => {
              // this.$swal.queue([
              //   {
              //     icon: "success",
              //     title: "Question Transferred",
              //     html: "Question Transferred Successfully",
              //     timer: 2000
              //   }
              // ]);
              this.$bvToast.toast("Question Transferred", {
            title: "Question Transferred Successfully",
            variant: "success",
            solid: true
          });
              this.dialog = false;
            });
        });
    },
    importS(title, link, system, grade, subject, week) {
      this.newActivity = {};
      this.newActivity.order = Number(this.order) + 1;
      this.newActivity.type = "video";
      this.newActivity.title = title;
      this.newActivity.link = link;

      this.newActivity.system = system;
      this.newActivity.grade = grade;
      this.newActivity.subject = subject;
      this.newActivity.week = week;

      firebase
        .firestore()
        .collection(this.path + "/lesson/")
        .add(this.newActivity)
        .then(() => {
          this.AddLog(
            this.$store.state.username,
            this.$store.state.firstname,
            this.$store.state.lastname,
            "Import",
            this.newActivity,
            this.path,
            "Vidoe"
          );

          this.$bvToast.toast("Activity Imported", {
            title: "Activity Imported Successfully",
            variant: "success",
            solid: true
          });
        })
        .catch(error => {
          alert("Error adding document: ", error);
        });
    }
  }
};
</script>
