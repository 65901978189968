<template>
<div>
  <v-row>
    <v-col cols="12">
      <v-card flat outlined>
        <v-card-title
          ><v-icon style="margin-right: 10px">mdi-cog</v-icon> Subject's Main
          Setting</v-card-title
        >
        <v-card-text>
          <p>select breakdown</p>
        <b-select
                    label="breakdown"
                    v-model.trim="breakdown"
                    :options="breakdowns"
                    @change="onBreakdownChange()"
                  >
                  </b-select>
          </v-card-text>
        <v-card-text>
          <v-text-field
            type="number"
            v-model="parGrade"
            label="Max Participation Grade"
          />

          <b-select
            label="Calculated Grade"
            v-model.trim="calgrade"
            :options="['Average Grade', 'Highest Grade', 'Latest Grade']"
          >
          </b-select>

          <v-row>
            <v-spacer />
            <v-btn
              color="green"
              small
              dark
              @click="save(parGrade, calgrade)"
              depressed
              ><v-icon size="18">mdi-content-save-outline</v-icon> Save
              Setting</v-btn
            ></v-row
          >
        </v-card-text>
      </v-card>
      <br />
      <v-card v-if="master == true" flat outlined>
        <v-card-title>Subject's Mirror</v-card-title>

        <v-card-text>
          <v-card style="background-color: #d7e9f9" flat outlined
            ><v-card-title
              ><v-icon style="margin-right: 10px">mdi-vector-link</v-icon>
              Subject's Mirror</v-card-title
            >
            <v-card-text>
              <div v-if="this.mirror.length > 20">
                <v-alert type="error">
                  This Subject Is a Mirror of another Subject ({{
                    this.mirror.split("/")[3] +
                    " / " +
                    this.mirror.split("/")[5] +
                    " / " +
                    this.mirror.split("/")[7]
                  }})
                </v-alert>
              </div>
              <v-row>
                <v-col class="d-flex" cols="12" sm="3">
                  <b-select
                    label="School"
                    v-model.trim="school"
                    :options="schools"
                    @change="onSchoolChange()"
                  >
                  </b-select>
                </v-col>

                <v-col class="d-flex" cols="12" sm="3">
                  <b-select
                    label="Grade Level"
                    v-model.trim="grade"
                    :options="level"
                    @change="onGradeChange()"
                  >
                  </b-select>
                </v-col>
                <v-col class="d-flex" cols="12" sm="3">
                  <b-select
                    label="Subject"
                    v-model.trim="subject"
                    :options="subjects"
                  >
                  </b-select>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="red" small dark @click="clearMirror" depressed
                ><v-icon size="18">mdi-link-off</v-icon> Clear Mirror</v-btn
              >
              <v-btn color="green" small dark @click="startMirror" depressed
                ><v-icon size="18">mdi-link</v-icon> Start Mirror</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-card-text></v-card
      >

      <br />
      <v-card flat outlined>
        <v-card-title>Subject's Import</v-card-title>
        <v-card-text>
          <v-card style="background-color: #d7e9f9" flat outlined
            ><v-card-title
              ><v-icon style="margin-right: 10px"
                >mdi-swap-vertical-bold</v-icon
              >
              Subject's Import</v-card-title
            >
            <v-card-text>
              <v-row>
                <v-col class="d-flex" cols="12" sm="3">
                  <b-select
                    label="School"
                    v-model.trim="school"
                    :options="schools"
                    @change="onSchoolChange()"
                  >
                  </b-select>
                </v-col>
                <!-- Grade Level -->

                <v-col class="d-flex" cols="12" sm="3">
                  <b-select
                    label="Grade Level"
                    v-model.trim="grade"
                    :options="level"
                    @change="onGradeChange()"
                  >
                  </b-select>
                </v-col>
                <v-col class="d-flex" cols="12" sm="3">
                  <b-select
                    label="Subject"
                    v-model.trim="subject"
                    :options="subjects"
                    @change="onSubjectChange()"
                  >
                  </b-select>
                </v-col>
              </v-row>
              <b-select
                label="Select Lesson To Import"
                v-model.trim="selectedLesson"
                :options="lessons"
              >
              </b-select>
            </v-card-text>
            <v-card-actions>
              <v-spacer />

              <v-btn color="green" small dark @click="importLessons" depressed
                ><v-icon size="18">mdi-swap-vertical-bold</v-icon> Start
                Import</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-card-text></v-card
      >
    </v-col>
  </v-row>
</div>
</template>
<script>
import firebase from "@/Firebase";
export default {
data() {
  return {
    school: "",
    breakdown:"",
    subjectPath: this.$store.state.subject,
    mirror: this.$store.state.mirror,
    parGrade: 0,
    grade: "",
    calgrade: "",
    subject: "",
    schools: [],
    level: [],
    subjects: [],
    lessons: [],
    breakdowns:[],
    question: "",
    assignment: "",
    counter: 0,
    selectedLesson: "",
    master: this.$store.state.master,
    newTask:{},

    newlesson: {},
    selected: [],
    gradesRef: firebase
      .firestore()
      .collection("schools/" + this.$store.state.school + "/grades/")
      .orderBy("order"),
    schoolsRef: firebase
      .firestore()
      .collection("schools/" + this.$store.state.school + "/branches/"),
  };
},
created() {
  this.breakdowns = [];
  this.schoolsRef.onSnapshot((querySnapshot) => {
    this.schools = [];
    querySnapshot.forEach((doc) => {
      this.schools.push({
        text: doc.data().title,
        value: doc.data().title,
      });
    });
  });

  firebase
    .firestore()
    .doc(this.$store.state.originalsubject)
    .get()
    .then((doc) => {
      if (doc.exists) {
        this.parGrade = doc.data().participation;
        this.calgrade = doc.data().calgrade;
      }
    });

    firebase
    .firestore()
    .collection("schools/" + this.$store.state.school +
          "/branches/" +
          this.$store.state.thisBranch +
          "/breakdown")
    .get()
    .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.breakdowns.push({
            text: doc.data().title,
        value: doc.data().title,
          })

        });
      });
      firebase
      .firestore()
      .doc(
        "schools/" +
          this.$store.state.school +
          "/branches/" +
          this.$store.state.thisBranch 
      )
      .get()
      .then((querySnapshot) => {
        if(querySnapshot.data().breakdown){
          this.breakdown = querySnapshot.data().breakdown
        }
      })
      .catch(error => {
        alert("Error adding document: ", error);
      });
},
methods: {
  onBreakdownChange() {
    this.$store.commit("breakdown", this.breakdown)
    firebase
      .firestore()
      .doc(
        "schools/" +
          this.$store.state.school +
          "/branches/" +
          this.$store.state.thisBranch 
      )
      .update({breakdown:this.breakdown})
      .then(() => {
        console.log("done")
      })
      .catch(error => {
        alert("Error adding document: ", error);
      });
  },

  onSchoolChange() {
    this.level = [];
    firebase
      .firestore()
      .collection(
        "schools/" +
          this.$store.state.school +
          "/branches/" +
          this.school +
          "/grades/"
      )
      .orderBy("order")
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.level.push({
            text: doc.data().title,
            value: doc.id,
          });
        });
      });
  },
  onGradeChange() {
    this.subjects = [];

    firebase
      .firestore()
      .collection(
        "schools/" +
          this.$store.state.school +
          "/branches/" +
          this.school +
          "/grades/" +
          this.grade +
          "/subjects"
      )
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.subjects.push({
            text: doc.data().title,
            value: doc.ref.path,
          });
        });
      });
  },
  onSubjectChange() {
    this.lessons = [];

    firebase
      .firestore()
      .collection(this.subject + "/weeks")
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          console.log(doc.ref.path)
          this.lessons.push({
            value: [doc.ref.path, doc.data()],
            text: doc.data().title,
          });
        });
      });
  },

  selectLesson(e, title, path) {
    if (e) {
      this.selected.push({ title: title, path: path });
    } else {
      this.selected.title[title]; // find index of your object
      //this.selected.splice(i, 1);
      //this.selected.delete(title, title);
    }
  },

  startMirror() {
    firebase
      .firestore()
      .doc(this.$store.state.originalsubject)
      .update({ mirror: this.subject })
      .then(() => {
        localStorage.setItem("mirror", this.subject);
        this.mirror = this.subject;
      });
  },
  save(grade, calgrade) {
    firebase
      .firestore()
      .doc(this.$store.state.originalsubject)
      .update({ participation: grade, calgrade: calgrade })
      .then(() => {});
  },
  clearMirror() {
    firebase
      .firestore()
      .doc(this.$store.state.originalsubject)
      .update({ mirror: "" })
      .then(() => {
        localStorage.setItem("mirror", "");
        this.mirror = "";
      });
  },

  importLessons() {
    var lessonPath = "";

    this.newlesson.title = this.selectedLesson[1].title;
    this.newlesson.order = 0;
    lessonPath = this.selectedLesson[0];
console.log("sub path: ",this.subjectPath,"lesson path: ",lessonPath)
    //! Create The New Weeks
    firebase
      .firestore()
      .collection(this.subjectPath + "/weeks")
      .add(this.newlesson)
      .then((newl) => {

        //import assignement
    //     firebase
    // .firestore()
    // .collection(this.subject+ "/tasks")
    // .where("week", "==", lessonPath)
    // .onSnapshot(querySnapshot => {
    //   querySnapshot.forEach(doc => {
    //     console.log("found",doc.data())
    //     this.newTask = doc.data()
    //     this.newTask.week = newl.path
    //     firebase
    // .firestore()
    // .collection(this.subjectPath+ "/tasks")
    // .add(this.newTask)
    // .then(newAss => {
    //   this.assignment = doc.ref.path;

        // firebase
        // .firestore()
        // .doc(this.assignment + "/details/fixedquestions")
        // .onSnapshot((querySnapshot) => {
        //   console.log("new ass path:: " ,newAss.path + "/details/fixedquestions")
        //     firebase
        //       .firestore()
        //       .doc(newAss.path + "/details/fixedquestions")
        //       .set(querySnapshot.data())
        //       .then(() => {});
        // });
// firebase
// .firestore()
// .doc(this.assignment + "/details/reading")
// .onSnapshot((querySnapshot) => {
//     firebase
//       .firestore()
//       .doc(newAss.path + "/details/reading")
//       .set(querySnapshot.data())
//       .then(() => {});
// })
// firebase
// .firestore()
// .doc(this.assignment + "/details/description")
// .onSnapshot((querySnapshot) => {
//     firebase
//       .firestore()
//       .doc(newAss.path + "/details/description")
//       .set(querySnapshot.data())
//       .then(() => {});
// })
    // })
    //   });
    // });
        // Import Questions
        firebase
          .firestore()
          .collection(lessonPath + "/questions")
          .onSnapshot((querySnapshot) => {
            querySnapshot.forEach((Que) => {
              console.log("question path", Que.ref.path)
              firebase
                .firestore()
                .collection(newl.path + "/questions")
                .add(Que.data())
                .then((newQue) => {
                console.log("new question path =>",newQue.path)
                            //!create new slink for standards
      firebase
      .firestore()
      .collection(this.subject + "/slink")
      .where("source", "==", Que.ref.path)
      .onSnapshot(querySnapshot => {
        querySnapshot.forEach(doc=>{
          var data =  {};
          data = doc.data();
          data.lesson = newl.path;
          data.source = newQue.path;
          firebase
      .firestore()
      .collection(this.subjectPath + "/slink")
      .add(data)
      .then({})
        })


      })
                  //! Import Question's Options
                  this.question = Que.ref.path;
                  firebase
                    .firestore()
                    .collection(this.question + "/options")
                    .onSnapshot((querySnapshot) => {
                      querySnapshot.forEach((oldQue) => {
                        firebase
                          .firestore()
                          .collection(newQue.path + "/options")
                          .add(oldQue.data())
                          .then(() => {});
                      });
                    });

                  //! Import Question's Body
                  firebase
                    .firestore()
                    .doc(this.question + "/details/details")
                    .get()
                    .then((doc) => {
                      firebase
                        .firestore()
                        .doc(newQue.path + "/details/details")
                        .set(doc.data())
                        .then(() => {});
                    });

                  //! End Import Question's Options
                });
            });
          });
        //End Import Questions

        // Import Lesson
        firebase
          .firestore()
          .collection(lessonPath + "/lesson")
          .onSnapshot((querySnapshot) => {
            querySnapshot.forEach((Lesson) => {
              console.log(Lesson.ref.path);
              firebase
                .firestore()
                .collection(newl.path + "/lesson")
                .add(Lesson.data())
                .then((act) => {
                                                //!create new slink for standards
      firebase
      .firestore()
      .collection(this.subject + "/slink")
      .where("source", "==", Lesson.ref.path)
      .onSnapshot(querySnapshot => {
        querySnapshot.forEach(doc=>{
          var data =  {};
          data = doc.data();
          data.lesson = newl.path;
          data.source = act.path;
          firebase
      .firestore()
      .collection(this.subjectPath + "/slink")
      .add(data)
      .then({})
        })
      })
                  //! Import Question's Options

                  // new q path
                  console.log(Lesson.ref.path);
                  this.question = Lesson.ref.path;

                  firebase
                    .firestore()
                    .collection(this.question + "/options")
                    .onSnapshot((querySnapshot) => {
                      querySnapshot.forEach((oldQue) => {
                        firebase
                          .firestore()
                          .collection(act.path + "/options")
                          .add(oldQue.data())
                          .then(() => {});
                      });
                    });

                  //! Import Question's Body
                  firebase
                    .firestore()
                    .doc(this.question + "/details/details")
                    .get()
                    .then((doc) => {
                      firebase
                        .firestore()
                        .doc(act.path + "/details/details")
                        .set(doc.data())
                        .then(() => {});
                    });

                  //! End Import Question's Options
                });
            });
          });
        //End Import Lesson

        //console.log(newl.path);
        this.$bvToast.toast("Import Complete", {
          title: "Import Complete Successfully",
          variant: "success",
          solid: true
        });
        // this.$swal.queue([
        //   {
        //     icon: "success",
        //     title: "Import Complete",
        //     html: "Import Complete Successfully",
        //     timer: 2000,
        //   },
        // ]);
      });
  },
},
};
</script>
